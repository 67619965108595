<template>
    <div>
        <CSTabBar
                :tabbar="tabbar"
                :checkedTab="checkedTab"
                :checkedChildTab="checkedChildTab"
                @changeTabBar="changeTabBar"
        ></CSTabBar>

        <div
                class="filter-panel"
                v-if="
        !(checkedTab === TAB_BAR_TYPE.BUILDINGBOOK && checkedChildTab === 2)
      "
        >
            <input
                    type="text"
                    class="cs-input"
                    v-model="queryParams.keyword"
                    style="width: 160px"
                    placeholder="搜索企业名称/联系人"
                    v-show="checkedTab !== TAB_BAR_TYPE.BUILDINGBOOK"
            />
            <CSSelect v-show="checkedTab !== TAB_BAR_TYPE.BUILDINGBOOK">
                <select v-model="queryParams.industry">
                    <option value="">全部行业</option>
                    <option
                            v-for="(val, type) in INDUSTRY_TYPES"
                            :key="type"
                            :value="type"
                    >
                        {{ val }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect v-show="checkedTab !== TAB_BAR_TYPE.BUILDINGBOOK">
                <select v-model="queryParams.source">
                    <option value="">全部客户来源</option>
                    <option
                            v-for="(val, type) in CUSTOMER_SOURCES"
                            :key="type"
                            :value="type"
                    >
                        {{ val }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect v-show="checkedTab == TAB_BAR_TYPE.PRIVATE">
                <select v-model="queryParams.intention">
                    <option value="">全部意向程度</option>
                    <option value="1">A</option>
                    <option value="2">B</option>
                    <option value="3">C</option>
                    <option value="4">D</option>
                    <option value="5">E</option>
                </select>
            </CSSelect>
            <CSSelect v-show="checkedTab == TAB_BAR_TYPE.PRIVATE">
                <select v-model="queryParams.orderBy">
                    <option value="" selected disabled>请选择</option>
                    <option value="1">按添加私有时间排序</option>
                    <option value="2">按跟进时间排序</option>
                    <option value="3">按剩余掉落天数排序</option>
                </select>
            </CSSelect>
            <CSSelect
                    v-show="
          checkedTab != TAB_BAR_TYPE.PUBLIC &&
          [JOB_LEVEL.SUPERVISOR, JOB_LEVEL.TEAM_SUPERVISOR].includes(
            +staffInfo.jobLevel
          ) &&
          checkedChildTab == 2
        "
            >
                <select v-model="queryParams.ownerId">
                    <option value="">全部员工</option>
                    <option
                            v-for="(staff, index) in staffList"
                            :key="`${staff.id}_${index}`"
                            :value="staff.id"
                    >
                        {{ staff.name }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect
                    v-show="
          checkedTab === TAB_BAR_TYPE.BUILDINGBOOK && checkedChildTab === 1
        "
                    i-width="36rpx"
                    style="width: 230px; margin-right: 0"
            >
                <el-date-picker
                        type="datetime"
                        placeholder="请选择开始时间"
                        v-model="queryCountUvPvByUserParams.startDate"
                        style="width: 214px"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        prefix-icon="el-icon-time"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <span
                    class="inline-gray"
                    v-show="
          checkedTab === TAB_BAR_TYPE.BUILDINGBOOK && checkedChildTab === 1
        "
            >
      </span>
            <CSSelect
                    v-show="
          checkedTab === TAB_BAR_TYPE.BUILDINGBOOK && checkedChildTab === 1
        "
                    i-width="36rpx"
                    style="width: 230px"
            >
                <el-date-picker
                        type="datetime"
                        placeholder="请选择结束时间"
                        v-model="queryCountUvPvByUserParams.endDate"
                        style="width: 214px"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        prefix-icon="el-icon-time"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <button
                    class="btn btn-primary"
                    style="margin-left: 10px"
                    @click="searchData()"
            >
                查询
            </button>
        </div>

        <div
                class="result-panel"
                v-if="
        !(checkedTab === TAB_BAR_TYPE.BUILDINGBOOK && checkedChildTab === 2)
      "
        >
            <CSTable
                    :thead-top="filterHeight"
                    v-if="checkedTab == TAB_BAR_TYPE.PUBLIC"
            >
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button
                                class="btn btn-primary sticky-right"
                                @click="getCustomerLeads"
                        >
                            获取客户
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th style="width: 240px">企业名称</th>
                        <th style="width: 140px">所属行业</th>
                        <th style="width: 100px">客户来源</th>
                        <th style="width: 160px">最新跟进内容</th>
                        <th style="width: 160px">最新跟进时间</th>
                        <th style="width: 100px">联系人</th>
                        <th style="width: 140px">电话</th>
                        <th style="width: 120px">操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="publicCustomerList.length > 0">
                        <tr v-for="customer in publicCustomerList" :key="customer.leadId">
                            <td>
                <span
                        class="allow-click"
                        @click="
                    toCustomerInfoPage(customer.leadId, 1, 1, customer.userId)
                  "
                >{{ customer.company }}</span
                >
                            </td>
                            <td>
                                {{ INDUSTRY_TYPES[customer.industry] || "-" }}
                            </td>
                            <td>
                                {{ CUSTOMER_SOURCES[customer.source] || "-" }}
                            </td>
                            <td>
                <span
                        class="allow-click"
                        @click="
                    toCustomerInfoPage(customer.leadId, 2, 1, customer.userId)
                  "
                        v-if="customer.latestFollowUpLog"
                >
                  {{ customer.latestFollowUpLog }}
                </span>
                                <template v-else>-</template>
                            </td>
                            <td>
                                <template v-if="customer.latestFollowUpTime">
                                    {{
                                    customer.latestFollowUpTime.substring(
                                    0,
                                    customer.latestFollowUpTime.length - 3
                                    )
                                    }}
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td>
                <span
                        class="allow-click"
                        @click="
                    toCustomerInfoPage(customer.leadId, 3, 1, customer.userId)
                  "
                >
                  {{ customer.contactsName || "-" }}
                </span>
                            </td>
                            <td>
                                {{ customer.contactsPhone || "-" }}
                            </td>
                            <td>
                                <div class="btn-group">
                                    <button
                                            type="button"
                                            class="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                    >
                                        操作
                                    </button>
                                    <ul
                                            class="dropdown-menu"
                                            style="min-width: 118px; width: 118px"
                                    >
                                        <li @click="openFollowDialog(customer.leadId)">
                                            <a style="width: 100%">添加跟进</a>
                                        </li>
                                        <li @click="addPrivateCustomerLeads(customer.lockId)">
                                            <a style="width: 100%">添加到私有客户</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>
            <CSTable
                    :thead-top="filterHeight"
                    v-if="checkedTab == TAB_BAR_TYPE.PRIVATE"
            >
                <template v-slot:header v-if="checkedChildTab === 1">
                    <div class="table-header-panel text-right sticky-right">
                        <button
                                style="margin-right: 20px"
                                class="btn btn-primary "
                                @click="importCustomersBtn"
                        >
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            导入客户
                        </button>
                        <button
                                class="btn btn-primary "
                                @click="openAddPrivateCustomer"
                        >
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            添加客户
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>首次接触时间</th>
                        <th>企业名称</th>
                        <th>所属行业</th>
                        <th>客户来源</th>
                        <th>
              <span style="vertical-align: middle; margin-right: 5px"
              >意向程度</span
              >
                            <!--              <svg t="1650447325639"  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5952" width="16" height="16" @click="lookLeadsLevels">-->
                            <!--                <path d="M30.264675 512a481.825668 481.825668 0 1 1 481.825667 481.825668 482.307493 482.307493 0 0 1-481.825667-481.825668z m60.228208 0a421.597459 421.597459 0 1 0 421.597459-421.597459 422.019057 422.019057 0 0 0-421.597459 421.597459z m387.448065 288.43289a38.907423 38.907423 0 0 1-13.611575-29.511822 36.739207 36.739207 0 0 1 13.611575-30.114104 38.365369 38.365369 0 0 1 29.451594-12.045642 41.196095 41.196095 0 0 1 30.114104 12.045642 37.823315 37.823315 0 0 1 12.708152 30.114104 41.256323 41.256323 0 0 1-12.708152 30.475474 44.870015 44.870015 0 0 1-30.114104 12.045641 38.305141 38.305141 0 0 1-29.451594-13.009293z m3.37278-141.596518v-24.512881a147.378426 147.378426 0 0 1 15.177508-67.395365A298.310316 298.310316 0 0 1 574.065169 475.01988a480.018821 480.018821 0 0 0 39.630161-40.473356 122.684861 122.684861 0 0 0-5.179626-157.67745 125.154217 125.154217 0 0 0-92.691213-32.101635 118.649571 118.649571 0 0 0-101.123162 44.689331 178.938007 178.938007 0 0 0-31.318668 105.459593h-50.471239a208.208917 208.208917 0 0 1 47.098459-138.52488 198.030349 198.030349 0 0 1 265.546171-12.045641 152.256911 152.256911 0 0 1 45.592754 116.421127 158.641101 158.641101 0 0 1-31.25844 95.281025c-8.913775 10.178567-31.137984 31.680038-66.55217 64.865781a181.347136 181.347136 0 0 0-42.942713 48.182567 122.02235 122.02235 0 0 0-17.707093 64.986237v24.51288z" fill="#999999" p-id="5953"></path>-->
                            <!--              </svg>-->
                            <svg t="1650454871363" viewBox="0 0 1024 1024" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="3312" width="18" height="18"
                                 @click="lookLeadsLevels">
                                <path d="M-0.056886 511.971557a511.971557 511.971557 0 1 1 511.971557 511.971557 512.5973 512.5973 0 0 1-511.971557-511.971557z m113.771457 0a398.2001 398.2001 0 1 0 398.2001-398.2001 398.712072 398.712072 0 0 0-398.2001 398.2001z m344.215544 266.964724a59.673129 59.673129 0 0 1 0-85.954336 58.1941 58.1941 0 0 1 43.062497-17.065718 61.777901 61.777901 0 0 1 44.655296 17.065718 58.307872 58.307872 0 0 1 17.520805 42.89184 59.616244 59.616244 0 0 1-17.520805 43.858897 65.930559 65.930559 0 0 1-44.655296 16.667518 58.876729 58.876729 0 0 1-43.005611-17.463919z m-2.389201-141.759235v-11.377146a169.860786 169.860786 0 0 1 16.041776-78.04722A423.059163 423.059163 0 0 1 564.704628 446.552969l9.556802-11.377145a87.945336 87.945336 0 0 0 21.44592-56.544415 91.586023 91.586023 0 0 0-160.815955-52.619299 132.202433 132.202433 0 0 0-19.910005 78.84362H324.191767a182.034331 182.034331 0 0 1 51.766013-138.459863A191.022277 191.022277 0 0 1 515.214044 216.165769a181.351703 181.351703 0 0 1 125.831231 42.152324 143.636465 143.636465 0 0 1 47.840898 114.738515 156.833954 156.833954 0 0 1-29.580579 97.900339q-12.685517 14.505861-68.262874 62.915616a120.313316 120.313316 0 0 0-31.799122 39.080495 115.876229 115.876229 0 0 0-11.946003 52.619299v11.377146z"
                                      fill="#999999" p-id="3313"></path>
                            </svg>

                        </th>
                        <th>最新跟进内容</th>
                        <th>最新跟进时间</th>
                        <th>剩余掉落天数</th>
                        <th>联系人</th>
                        <th>电话</th>
                        <th>意向房间</th>
                        <th>认租审核状态</th>
                        <th>备注</th>
                        <th>
                            {{ checkedChildTab === 1 ? "操作" : "所属员工" }}
                        </th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template
                            v-if="privateCustomerList && privateCustomerList.length > 0"
                    >
                        <tr v-for="customer in privateCustomerList" :key="customer.leadId">
                            <td>
                                {{ customer.firstCommunicationTime || "-" }}
                            </td>

                            <td>
                <span
                        class="allow-click"
                        @click="
                    toCustomerInfoPage(customer.leadId, 1, 2, customer.userId)
                  "
                >
                  {{ customer.company }}
                </span>
                            </td>
                            <td>
                                {{ INDUSTRY_TYPES[customer.industry] || "-" }}
                            </td>
                            <td>
                                {{ CUSTOMER_SOURCES[customer.source] || "-" }}
                            </td>
                            <td>
                                {{ leadsLevel[customer.intention] || "-" }}
                            </td>
                            <td>
                <span
                        v-if="
                    customer.latestFollowUpLog &&
                    customer.latestFollowUpLog.length > 0
                  "
                        class="allow-click"
                        @click="
                    toCustomerInfoPage(customer.leadId, 2, 2, customer.userId)
                  "
                >
                  {{ customer.latestFollowUpLog }}
                </span>
                                <template v-else> -</template>
                            </td>
                            <td>
                                <template v-if="customer.latestFollowUpTime">
                                    {{
                                    customer.latestFollowUpTime.substring(
                                    0,
                                    customer.latestFollowUpTime.length - 3
                                    )
                                    }}
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td>{{ customer.expiredTime | expiredDay }}</td>
                            <td>
                <span
                        class="allow-click"
                        @click="toCustomerInfoPage(customer.leadId, 3)"
                >
                  {{ customer.contactsName || "-" }}
                </span>
                            </td>
                            <td>
                                {{ customer.contactsPhone || "-" }}
                            </td>
                            <td>
                                <div
                                        style="min-width: 260px; text-align: center"
                                        @click="
                    lookIntentionRooms(
                      1,
                      customer.intentionRooms,
                      true,
                      customer.leadId
                    )
                  "
                                        v-if="
                    customer.intentionRooms &&
                    customer.intentionRooms.length > 0
                  "
                                        v-html="getIntentionRoomNames(customer.intentionRooms, true)"
                                ></div>
                                <template v-else>-</template>
                            </td>
                            <td>
                                <template
                                        v-if="
                    customer.subscribeAuditComment &&
                    customer.subscribeAuditComment !== ''
                  "
                                >
                  <span
                          class="allow-click"
                          @click="
                      lookSubscribeAuditComment(
                        customer.subscribeAuditComment,
                        customer.subscribeAuditState
                      )
                    "
                  >{{
                      subscribeAuditState[customer.subscribeAuditState] || "-"
                    }}</span
                  >
                                </template>
                                <template v-else>
                                    {{ subscribeAuditState[customer.subscribeAuditState] || "-" }}
                                </template>
                            </td>
                            <td>
                <span
                        class="allow-click"
                        v-if="customer.comment"
                        @click="lookCustomerComment(customer.comment)"
                >
                  查看
                </span>
                                <template v-else>-</template>
                            </td>
                            <td>
                                <div class="btn-group" v-if="checkedChildTab === 1">
                                    <button
                                            type="button"
                                            class="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                    >
                                        操作
                                    </button>
                                    <ul
                                            class="dropdown-menu"
                                            style="min-width: 76px; width: 100px"
                                    >
                                        <li @click="openFollowDialog(customer.leadId)">
                                            <a style="width: 100%">添加跟进</a>
                                        </li>
                                        <li
                                                @click="
                        openAddIntentionDialog(
                          customer.leadId,
                          customer.intentionRooms
                        )
                      "
                                        >
                                            <a style="width: 100%">意向房间</a>
                                        </li>
                                        <li
                                                @click="
                        openConfessToRentDialog(
                          customer.leadId,
                          customer.intentionRooms
                        )
                      "
                                        >
                                            <a style="width: 100%">申请认租</a>
                                        </li>
                                        <li @click="releaseOpportunity(customer.leadId)">
                                            <a style="width: 100%">释放</a>
                                        </li>
                                    </ul>
                                </div>
                                <div v-else>
                                    {{ customer.userName || "-" }}
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>

            <CSTable
                    :thead-top="filterHeight"
                    v-if="checkedTab == TAB_BAR_TYPE.SUBSCRIPTION"
            >
                <template v-slot:thead>
                    <tr>
                        <th>审核通过时间</th>
                        <th>企业名称</th>
                        <th>认租房间</th>
                        <th>认租定金</th>
                        <th>认租保留期</th>
                        <th>所属行业</th>
                        <th>客户来源</th>
                        <th>联系人</th>
                        <th>电话</th>
                        <th v-if="checkedChildTab === 2">所属员工</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template
                            v-if="
              subscriptionCustomerList && subscriptionCustomerList.length > 0
            "
                    >
                        <tr
                                v-for="customer in subscriptionCustomerList"
                                :key="customer.leadId"
                        >
                            <td>{{ customer.auditTime }}</td>
                            <td>
                <span
                        class="allow-click"
                        @click="
                    toCustomerInfoPage(customer.leadId, 1, 3, customer.userId)
                  "
                >
                  {{ customer.company }}
                </span>
                            </td>
                            <td>
                                <div
                                        style="min-width: 260px; text-align: center"
                                        v-if="
                    customer.intentionRooms &&
                    customer.intentionRooms.length > 0
                  "
                                        @click="lookIntentionRooms(2, customer.intentionRooms)"
                                        v-html="getIntentionRoomNames(customer.intentionRooms)"
                                ></div>
                                <template v-else> -</template>
                            </td>
                            <td>{{ customer.moneyAmount }}</td>
                            <td>{{ customer.retentionDays }}</td>
                            <td>
                                {{ INDUSTRY_TYPES[customer.industry] || "-" }}
                            </td>
                            <td>
                                {{ CUSTOMER_SOURCES[customer.source] }}
                            </td>
                            <td>
                <span
                        class="allow-click"
                        @click="
                    toCustomerInfoPage(customer.leadId, 3, 3, customer.userId)
                  "
                        v-if="customer.contactsName"
                >{{ customer.contactsName }}</span
                >
                                <template v-else>-</template>
                            </td>
                            <td>{{ customer.contactsPhone || "-" }}</td>
                            <td v-if="checkedChildTab === 2">
                                {{ customer.userName || "-" }}
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>

            <CSTable
                    :thead-top="filterHeight"
                    v-if="checkedTab === TAB_BAR_TYPE.BUILDINGBOOK && checkedChildTab === 1"
            >
                <template v-slot:thead>
                    <tr>
                        <th>UV独立访客</th>
                        <th>PV访问量</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr>
                        <td>
              <span class="allow-click" @click="queryUV()">
                {{ UvPv.uv }}
              </span>
                        </td>
                        <td>
              <span class="allow-click" @click="queryPV()">
                {{ UvPv.pv }}
              </span>
                        </td>
                    </tr>
                </template>
            </CSTable>

            <Pagination v-show="checkedTab !== TAB_BAR_TYPE.BUILDINGBOOK"/>
        </div>
        <div
                v-if="checkedTab == TAB_BAR_TYPE.BUILDINGBOOK && checkedChildTab === 2"
                class="building-book-setting filter-panel"
        >
      <span style=" margin-top: 15px; float: left">
          您的照片
      </span>

            <div v-if="buildingBookInfo.portrait"
                 style="
          margin-left: 20px;
          margin-top: 15px;
          width: 80px;
          height: 80px;
          float: left;
        ">
                <img
                        :src="buildingBookInfo.portrait"
                        alt=""
                        @click="uploadAddEnterprisePhoto"
                        style=" width: 80px;height: 80px;float: left"
                />
                <img
                        @click="deleteFacePhoto()"
                        src="../../assets/icon4.png"
                        style="
                  width: 15px;
                  height: 15px;
                  position: relative;
                  top: -90px;
                  right: -75px;
                "
                />
            </div>
            <div v-else style="
          margin-left: 20px;
          margin-top: 15px;
          width: 80px;
          height: 80px;
          float: left;
        ">
                <div class="upload-photo" @click="uploadAddEnterprisePhoto">
                    <div class="text-center">
                        <img
                                src="../../assets/upload.png"
                                alt=""
                                style="margin: 5px auto"
                        />
                        <p style="color: #999">上传照片</p>
                    </div>
                </div>
            </div>
            <input
                    type="file"
                    class="file"
                    accept="images/*"
                    id="uploadAddEnterprisePhotoId"
                    hidden
                    @change="showImage($event)"
            />


            <div style="clear: both"></div>
            <span>联系电话</span>
            <input
                    type="text"
                    placeholder="限11位数字"
                    v-model="buildingBookInfo.phone"
                    maxlength="11"
                    style=" margin-top: 15px"
            />
            <br/>
            <span style="margin-top: 0px;padding-left: 2em">口号</span>
            <input
                    type="text"
                    placeholder="限15个字"
                    style="margin-left: 20px;margin-top: 13px"
                    v-model="buildingBookInfo.slogan"
                    maxlength="15"
            />
            <div class="setting-hint" style="margin-top: 5px">
                <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                您的信息将会在电子楼书中展示，方便目标客户联系您，请认真仔细填写。
            </div>
            <button
                    class="btn btn-primary"
                    style=" margin-top: 20px;width: 50px;height: 30px;padding-left: 0;padding-right: 0"
                    @click="settingBuildingBook"

            >
                保存
            </button>
            <br/>
            <span style=" margin-top: 0px;margin-bottom: 0">电子楼书</span>
            <button @click="openBuildBookDialog()" class="btn btn-primary"
                    style="margin-left: 20px;margin-bottom:0px;width: 90px;height: 30px;padding-left: 0;padding-right: 0">
                生成二维码
            </button>
            <div class="setting-hint" style="margin-top: 10px">
                <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                生成二维码，微信扫一扫，将电子楼书分享给目标客户，提高成单率。
            </div>
        </div>

        <CSDialog
                :dialogVisible="followDialog.visible"
                @onClose="followDialog.onClose"
                @onConfirm="followDialog.onConfirm"
                dialogTitle="微信扫一扫分享电子楼书"
        >
            <template v-slot:dialog-content>
                <div class="follow-dialog-content">
                    <div class="form-panel">
                        <div class="form-item">
                            <div class="form-item-label">跟进对象</div>
                            <div>
                                <CSSelect height="40px">
                                    <select v-model="followDialog.data.contactsId">
                                        <option value="">请选择</option>
                                        <option
                                                v-for="contact in followDialog.contacts"
                                                :key="contact.id"
                                                :value="contact.id"
                                        >
                                            {{ contact.name }}
                                        </option>
                                    </select>
                                </CSSelect>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>


        <!-- 添加客户弹窗 -->
        <CSDialog
                :dialogVisible="addCustomerDialog.visible"
                @onClose="addCustomerDialog.onClose"
                @onConfirm="addCustomerDialog.onConfirm"
                dialogTitle="添加客户"
        >
            <template v-slot:dialog-content>
                <div class="add-customer-content">
                    <div class="form-panel">

                        <div
                                :class="['form-item']"
                        >
                            <div class="form-item-label">首次接触时间</div>
                            <div>
                                <CSSelect i-width="36px" height="40px">
                                    <el-date-picker
                                            style="width: 250px; font-size: 24px; line-height: 32px"
                                            v-model="addCustomerDialog.data.firstCommunicationTime"
                                            type="date"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            placeholder="请选择时间"
                                            prefix-icon="el-icon-time"
                                    ></el-date-picker>
                                </CSSelect>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">首次接触沟通记录</div>
                            <div>
                                <input
                                        type="text"
                                        v-model="addCustomerDialog.data.firstCommunicationRecord"
                                        class="cs-input"
                                        placeholder="限100个字"
                                        maxlength="100"
                                />
                            </div>
                        </div>
                        <div
                                :class="['form-item', { error: addPrivateCustomerLeads.showTip }]"
                        >
                            <div class="form-item-label">企业名称</div>
                            <div>
                                <input
                                        type="text"
                                        v-model="addCustomerDialog.data.company"
                                        class="cs-input"
                                        placeholder="限30个字"
                                        maxlength="30"
                                />
                            </div>
                        </div>
                        <div
                                class="form-item"
                                v-if="addCustomerDialog.showTip"
                                style="margin-top: -23px; margin-bottom: 0"
                        >
                            <div class="form-item-label"></div>
                            <div style="font-size: 20px">
                                <div class="tips">
                                    {{ addCustomerDialog.showTipText }}
                                </div>
                                <div
                                        v-if="addCustomerDialog.showTransferBtn"
                                        class="allow-click"
                                        style="margin-left: 20px"
                                        @click="addCustomerDialog.addPrivatePool"
                                >
                                    转移至我的私有客户池
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">所属行业</div>
                            <div>
                                <CSSelect height="40px">
                                    <select v-model="addCustomerDialog.data.industry">
                                        <option value="">请选择</option>
                                        <option
                                                v-for="(val, type) in INDUSTRY_TYPES"
                                                :key="type"
                                                :value="type"
                                        >
                                            {{ val }}
                                        </option>
                                    </select>
                                </CSSelect>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">详细地址</div>
                            <div>
                                <input
                                        type="text"
                                        v-model="addCustomerDialog.data.address"
                                        class="cs-input"
                                        placeholder="限30个字"
                                        maxlength="30"
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">联系人</div>
                            <div>
                                <input
                                        type="text"
                                        v-model="addCustomerDialog.data.contactsName"
                                        class="cs-input"
                                        placeholder="限10个字"
                                        maxlength="10"
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">电话</div>
                            <div>
                                <input
                                        type="text"
                                        class="cs-input"
                                        v-model="addCustomerDialog.data.contactsPhone"
                                        placeholder="限11位数字"
                                        maxlength="11"
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">职位</div>
                            <div>
                                <input
                                        type="text"
                                        class="cs-input"
                                        placeholder="限5个字"
                                        v-model="addCustomerDialog.data.jobTitle"
                                        maxlength="5"
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">是否决策人</div>
                            <div>
                                <CSRadio
                                        :items="decisionMaking"
                                        v-model="addCustomerDialog.data.decisionMaker"
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">意向程度</div>
                            <div>
                                <CSRadio
                                        :items="leadsLevel"
                                        v-model="addCustomerDialog.data.intention"
                                />
                                <img
                                        @click="lookLeadsLevels"
                                        style="
                    width: 20px;
                    height: 20px;
                    margin-left: 15px;
                    vertical-align: middle;
                    margin-top: 5px;
                  "
                                        src="../../assets/icon/question.png"
                                        alt=""
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">客户来源</div>
                            <div>
                                <CSSelect height="40px">
                                    <select v-model="addCustomerDialog.data.source">
                                        <option value="">请选择</option>
                                        <option
                                                v-for="(val, type) in CUSTOMER_SOURCES"
                                                :key="type"
                                                :value="type"
                                        >
                                            {{ val }}
                                        </option>
                                    </select>
                                </CSSelect>
                            </div>
                        </div>


                        <div class="form-item">
                            <div class="form-item-label">租/购</div>
                            <div>
                                <CSRadio
                                        :items="leaseMethodList"
                                        v-model="addCustomerDialog.data.leaseMethod"
                                />
                            </div>
                        </div>

                        <div class="form-item">
                            <div class="form-item-label">认知途径</div>
                            <div>
                                <CSSelect height="40px">
                                    <select v-model="addCustomerDialog.data.cognitivePathway">
                                        <option value="">请选择</option>
                                        <option
                                                v-for="(val, type) in CONITIVE_PATHWAY_NEW"
                                                :key="type"
                                                :value="type"
                                        >
                                            {{ val }}
                                        </option>
                                    </select>
                                </CSSelect>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">是否已来访</div>
                            <div>
                                <CSRadio
                                        :items="cognitivePathwayList"
                                        v-model="addCustomerDialog.data.visited"
                                />
                                <img
                                        @click="lookLeadsLevels"
                                        style="
                    width: 20px;
                    height: 20px;
                    margin-left: 15px;
                    vertical-align: middle;
                    margin-top: 5px;
                  "
                                        src="../../assets/icon/question.png"
                                        alt=""
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">主营业务</div>
                            <div>
                                <input
                                        type="text"
                                        class="cs-input"
                                        placeholder="限50个字"
                                        v-model="addCustomerDialog.data.mainBusiness"
                                        maxlength="50"
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">现有办公地址所属区位</div>
                            <div>
                                <input
                                        type="text"
                                        class="cs-input"
                                        placeholder="限10个字"
                                        v-model="addCustomerDialog.data.affiliationLocation"
                                        maxlength="10"
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">现有办公面积</div>
                            <div>
                                <input
                                        type="number"
                                        class="cs-input"
                                        placeholder="保留两位小数"
                                        v-model="addCustomerDialog.data.officeArea"
                                />
                            </div>
                        </div>

                        <div class="form-item">
                            <div class="form-item-label">企业人数</div>
                            <div>
                                <input
                                        type="number"
                                        class="cs-input"
                                        placeholder="限整数"
                                        v-model="addCustomerDialog.data.companyNumber"
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">选址目的</div>
                            <div>
                                <CSSelect height="40px">
                                    <select v-model="addCustomerDialog.data.purposeOfSiteSelection">
                                        <option value="">请选择</option>
                                        <option
                                                v-for="(val, type) in PURPOSE_OFSITE_SELECTION"
                                                :key="type"
                                                :value="type"
                                        >
                                            {{ val }}
                                        </option>
                                    </select>
                                </CSSelect>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">需求面积</div>
                            <div>
                                <input
                                        type="number"
                                        class="cs-input"
                                        placeholder="保留两位小数"
                                        v-model="addCustomerDialog.data.requiredArea"
                                />
                            </div>
                        </div>
                        <div
                                :class="['form-item']"
                        >
                            <div class="form-item-label">预计用房时间</div>
                            <div>
                                <CSSelect i-width="36px" height="40px">
                                    <el-date-picker
                                            style="width: 250px; font-size: 24px; line-height: 32px"
                                            v-model="addCustomerDialog.data.estimatedOccupancyTime"
                                            type="date"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            placeholder="请选择时间"
                                            prefix-icon="el-icon-time"
                                    ></el-date-picker>
                                </CSSelect>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">关注点</div>
                            <div>
                                <input
                                        type="text"
                                        class="cs-input"
                                        placeholder="限50个字"
                                        v-model="addCustomerDialog.data.focus"
                                        maxlength="50"
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">回访记录</div>
                            <div>
                                <input
                                        type="text"
                                        class="cs-input"
                                        placeholder="限50个字"
                                        v-model="addCustomerDialog.data.returnVisitRecord"
                                        maxlength="50"
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">接待人</div>
                            <div>
                                <input
                                        type="text"
                                        class="cs-input"
                                        placeholder="限10个字"
                                        v-model="addCustomerDialog.data.receptionist"
                                        maxlength="10"
                                />
                            </div>
                        </div>

                        <div class="form-item">
                            <div class="form-item-label vertical-top">备注</div>
                            <div>
                <textarea
                        v-model="addCustomerDialog.data.comment"
                        class="cs-input"
                        placeholder="限100个字"
                        maxlength="100"
                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!-- 跟进对象 -->
        <CSDialog
                :dialogVisible="followDialog.visible"
                @onClose="followDialog.onClose"
                @onConfirm="followDialog.onConfirm"
                dialogTitle="跟进客户"
        >
            <template v-slot:dialog-content>
                <div class="follow-dialog-content">
                    <div class="form-panel">
                        <div class="form-item">
                            <div class="form-item-label">跟进对象</div>
                            <div>
                                <CSSelect height="40px">
                                    <select v-model="followDialog.data.contactsId">
                                        <option value="">请选择</option>
                                        <option
                                                v-for="contact in followDialog.contacts"
                                                :key="contact.id"
                                                :value="contact.id"
                                        >
                                            {{ contact.name }}
                                        </option>
                                    </select>
                                </CSSelect>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">意向程度</div>
                            <div>
                                <CSRadio
                                        :items="leadsLevel"
                                        v-model="followDialog.data.intention"
                                />
                                <img
                                        @click="lookLeadsLevels"
                                        style="
                    width: 20px;
                    height: 20px;
                    margin-left: 15px;
                    vertical-align: middle;
                  "
                                        src="../../assets/icon/question.png"
                                        alt=""
                                />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label vertical-top">跟进内容</div>
                            <div>
                <textarea
                        v-model="followDialog.data.content"
                        class="cs-input"
                        placeholder="限100个字"
                        maxlength="100"
                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!-- 意向房间 -->
        <CSDialog
                :dialogWidth="504"
                @click="addIntentionDialog.treePanelVisible = false"
                :dialogVisible="addIntentionDialog.visible"
                @onClose="addIntentionDialog.onClose"
                @onConfirm="addIntentionDialog.onConfirm"
                dialogTitle="意向房间"
        >
            <template v-slot:dialog-content>
                <div class="confess-rent-dialog-content">
                    <div class="form-panel">
                        <div class="form-item">
                            <div class="form-item-label">意向房间</div>
                            <div>
                                <div class="tree-wrap" style="--height: 40px">
                                    <CSSelect height="40px" style="width: 250px" iWidth="36px">
                                        <div
                                                :class="[
                        'room-select',
                        { unselect: addIntentionDialog.roomNames.length > 0 },
                      ]"
                                                @click.stop="addIntentionDialog.treePanelVisible = true"
                                        >
                                            <template v-if="addIntentionDialog.roomNames.length > 0">
                        <span
                                v-for="(
                            roomName, roomIndex
                          ) in addIntentionDialog.roomNames"
                                :key="roomIndex"
                        >
                          {{ roomName }}
                        </span>
                                            </template>
                                            <template v-else>
                                                <div style="color: #999">请选择</div>
                                            </template>
                                        </div>
                                        <TreePanel v-show="addIntentionDialog.treePanelVisible">
                                            <CSTree
                                                    :tree="addIntentionDialog.rooms"
                                                    @change="addIntentionDialog.onChangeRooms"
                                            ></CSTree>
                                        </TreePanel>
                                    </CSSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>
        <!-- 查看意向房间 -->
        <CSDialog
                :dialogWidth="504"
                :dialogVisible="lookIntentionDialog.visible"
                @onClose="lookIntentionDialog.onClose"
                :dialog-show-confirm-btn="false"
                dialogTitle="意向房间"
        >
            <template v-slot:dialog-content>
                <div style="padding: 30px; font-size: 24px">
                    <div class="comment-panel">
                        <div class="item">
                            <div style="width: 100%; max-width: 100%">
                                <p
                                        v-for="(room, index) in lookIntentionDialog.rooms"
                                        :key="room.roomId + '_' + index"
                                        class="text-center"
                                >
                                    <span>{{ room.fullName }}</span>
                                    <img
                                            src="../../assets/delete.png"
                                            class="delete-icon"
                                            @click="lookIntentionDialog.deleteIntentionRoom(index)"
                                            alt=""
                                    />
                                    <span
                                            class="cs-tag error"
                                            style="margin-left: 20px"
                                            v-if="room.hasTenant"
                                    >房间已被认租</span
                                    >
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!-- 认租房间 -->
        <CSDialog
                :dialogWidth="504"
                @click="confessToRentDialog.treePanelVisible = false"
                :dialogVisible="confessToRentDialog.visible"
                @onClose="confessToRentDialog.onClose"
                @onConfirm="confessToRentDialog.onConfirm"
                dialogTitle="认租房间"
                dialog-confirm-btn-text="确定"
        >
            <template v-slot:dialog-content>
                <div class="confess-rent-dialog-content">
                    <div class="form-panel">
                        <div class="form-item">
                            <div class="form-item-label">认租定金</div>
                            <div>
                                <input
                                        placeholder="1-999999"
                                        maxlength="6"
                                        type="text"
                                        class="cs-input"
                                        style="width: 250px"
                                        v-model="confessToRentDialog.moneyAmount"
                                />
                                元
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">认租保留期</div>
                            <div>
                                <CSSelect height="40px" i-width="36px">
                                    <select
                                            style="width: 238px"
                                            v-model="confessToRentDialog.expiredDays"
                                    >
                                        <option value="">请选择</option>
                                        <option :value="3">3天</option>
                                        <option :value="7">7天</option>
                                        <option :value="14">14天</option>
                                        <option :value="30">30天</option>
                                    </select>
                                </CSSelect>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">认租房间</div>
                            <div>
                                <div class="tree-wrap" style="--height: 40px">
                                    <CSSelect height="40px" style="width: 250px" i-width="36px">
                                        <div
                                                :class="[
                        'room-select',
                        {
                          unselect: confessToRentDialog.roomNames.length === 0,
                        },
                      ]"
                                                @click.stop="confessToRentDialog.treePanelVisible = true"
                                        >
                                            <template v-if="confessToRentDialog.roomNames.length > 0">
                        <span
                                v-for="(
                            roomName, roomIndex
                          ) in confessToRentDialog.roomNames"
                                :key="roomIndex"
                        >
                          {{ roomName }}
                        </span>
                                            </template>
                                            <template v-else> 请选择</template>
                                        </div>
                                        <div @click.stop>
                                            <TreePanel v-show="confessToRentDialog.treePanelVisible">
                                                <CSTree
                                                        :tree="confessToRentDialog.rooms"
                                                        @change="confessToRentDialog.onChangeRooms"
                                                ></CSTree>
                                            </TreePanel>
                                        </div>
                                    </CSSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!--楼书二维码-->
        <CSDialog
                :dialogWidth="380"
                :dialogVisible="buildBookDialog.visible"
                @onClose="buildBookDialog.onClose"
                :dialog-show-confirm-btn="false"
                dialogTitle="微信扫一扫分享电子楼书"
                dialogCancelBtnText="关闭"

        >
            <template v-slot:dialog-content>
                <div
                        style="
          width: 320px;
          height: 320px;
          margin: 30px;
        "
                >
                    <img
                            :src="buildBookDialog.qrCode"
                            alt=""
                            style="width: 320px;; height: 320px"
                    />
                </div>
            </template>
        </CSDialog>


        <CSDialog
                :dialogVisible="uploadExcelDialog.visible"
                :dialogWidth="550"
                dialog-title="上传客户"
                @onClose="closeUploadExcel"
                @onConfirm="uploadExcel"
        >
            <template v-slot:dialog-content>
                <div class="upload-excel-dialog">
                    <div class="form-item">
                        <div class="form-item-label">选择Excel</div>
                        <div>
                            <input
                                    type="file"
                                    hidden
                                    id="uploadExcel"
                                    @change="chooseExcel($event)"
                            />
                            <input
                                    type="text"
                                    class="cs-input"
                                    readonly
                                    v-model="uploadExcelDialog.file.name"
                                    placeholder="请选择"
                            />
                            <button
                                    class="btn btn-primary"
                                    style="margin-left: 12px"
                                    @click="triggerChooseExcel"
                            >
                                浏览
                            </button>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-item-label">模版</div>
                        <div>
                            <a
                                    class="allow-click"
                                    href="https://cosmoss-img.oss-cn-beijing.aliyuncs.com/excel/%E4%B8%8A%E4%BC%A0%E5%AE%A2%E6%88%B7.xlsx"
                                    target="_blank"
                            >下载</a
                            >
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

    </div>
</template>

<script>
    import CSDialog from "@/components/common/CSDialog";
    import CSTabBar from "@/components/common/CSTabBar";
    import CSSelect from "@/components/common/CSSelect";
    import CSRadio from "@/components/common/CSRadio";
    import TreePanel from "@/components/common/TreePanel";
    import CSTree from "@/components/common/CSTree";
    import dayjs from "dayjs";
    import Pagination from "@/components/Pagination";

    import {
        leaseCrmUploadExcelUrl,
        getCustomerLeadsUrl,
        lockedToOpportunityUrl,
        addFollowUpLogUrl,
        queryLeadContactsUrl,
        queryPublicCustomerUrl,
        queryOpportunityUrl,
        querySubscriptionsUrl,
        addOpportunityUrl,
        releaseOpportunityUrl,
        queryBuildingRoomsUrl,
        addIntentionRoomsUrl,
        queryDepartmentStaffUrl,
        queryIntentionRoomsUrl,
        publicToOpportunityUrl,
        queryDepartmentUrl,
        getBuildingBookUrl,
        settingBuildingBookUrl,
        queryCountUvPvByUserUrl,
        generateQrCodeUrl, uploadExcelUrl,
    } from "@/requestUrl";
    import {
        PURPOSE_OFSITE_SELECTION,
        CONITIVE_PATHWAY_NEW,
        INDUSTRY_TYPES,
        CUSTOMER_SOURCES,
        JOB_LEVEL,
        DEPARTMENT_TYPES,
        ORG_LEVEL,
    } from "@/constant";
    import CSTable from "@/components/common/CSTable";

    const TAB_BAR_TYPE = {
        PUBLIC: 1,
        PRIVATE: 2,
        SUBSCRIPTION: 3,
        BUILDINGBOOK: 4,
    };
    export default {
        name: "CustomerLeads",
        components: {
            CSTable,
            CSSelect,
            CSTabBar,
            CSRadio,
            CSDialog,
            TreePanel,
            CSTree,
            Pagination,
        },
        filters: {
            expiredDay(val) {
                if (!val) {
                    return "-";
                }
                const second = dayjs(val).diff(dayjs(), "millisecond") / 1000,
                    day = Math.floor(second / 60 / 60 / 24),
                    hour = Math.floor((second / 60 / 60) % 24),
                    minute = Math.floor(second / 60 - day * 24 * 60 - hour * 60),
                    timeArr = [];
                if (day !== 0) {
                    timeArr.push(day.toString().padStart(2, "0") + "天");
                }
                if (hour !== 0) {
                    timeArr.push(hour.toString().padStart(2, "0") + "小时");
                } else {
                    timeArr.push(minute.toString().padStart(2, "0") + "分");
                }
                return timeArr.join("");
            },
        },
        data() {
            return {
                PURPOSE_OFSITE_SELECTION,
                CONITIVE_PATHWAY_NEW,
                INDUSTRY_TYPES,
                CUSTOMER_SOURCES,
                TAB_BAR_TYPE,
                JOB_LEVEL,
                filterHeight: 0,
                uploadExcelDialog: {
                    visible: false,
                    file: {name: ""},
                    source: "",
                    regionCode: this.$vc.getCurrentRegion().code,
                },
                leaseMethodList:{
                    1:'租赁',
                    2:'购买'
                },
                tabbar: {
                    [TAB_BAR_TYPE.PUBLIC]: {
                        name: "公有客户",
                        children: [],
                    },
                    [TAB_BAR_TYPE.PRIVATE]: {
                        name: "私有客户",
                        children: [
                            {id: 1, name: "我的客户"},
                            {id: 2, name: "下级客户"},
                        ],
                    },
                    [TAB_BAR_TYPE.SUBSCRIPTION]: {
                        name: "认租客户",
                        children: [
                            {id: 1, name: "我的客户"},
                            {id: 2, name: "下级客户"},
                        ],
                    },
                    [TAB_BAR_TYPE.BUILDINGBOOK]: {
                        name: "电子楼书",
                        children: [
                            {id: 1, name: "UV/PV统计"},
                            {id: 2, name: "设置"},
                        ],
                    },
                },
                //  选中的tab
                checkedTab: 1,
                // 选中的子级tab
                checkedChildTab: 1,
                lookIntentionDialog: {
                    visible: false,
                    rooms: [],
                    leadId: null,
                    onClose: () => (this.lookIntentionDialog.visible = false),
                    deleteIntentionRoom: (index) => {
                        this.lookIntentionDialog.rooms.splice(index, 1);
                        const checkedRoomIds = this.lookIntentionDialog.rooms.map(
                            (room) => room.roomId
                        );
                        this.confirmIntentionRoom({
                            leadId: this.lookIntentionDialog.leadId,
                            roomIds: checkedRoomIds,
                            type: 1,
                            regionCode: this.$vc.getCurrentRegion().code,
                        }).then((res) => {
                            if (res.code !== 0) {
                                return;
                            }
                            if (this.lookIntentionDialog.rooms.length === 0) {
                                this.lookIntentionDialog.onClose();
                                this.searchData();
                            }
                            this.$vc.toast("删除成功");
                        });
                    },
                },
                // 添加客户
                addCustomerDialog: {
                    visible: false,
                    onClose: () => (this.addCustomerDialog.visible = false),
                    onConfirm() {
                    },
                    showTransferBtn: false,
                    addPrivatePool() {
                    },
                    showTipText: "",
                    showTip: false,
                    data: {
                        receptionist: '',//接待人
                        returnVisitRecord: '',//回访记录
                        focus: '',//关注点
                        estimatedOccupancyTime: '',// 预计用房时间
                        requiredArea: '',// 需求面积
                        purposeOfSiteSelection: '',//新加 选址目的 1:办公需求;2:生产需求;3仓库需求;4:商业配套需求
                        companyNumber: '',//企业人数
                        officeArea: '',//现有办公面积
                        affiliationLocation: '',//办公地址所属区位
                        mainBusiness: '',//主营业务
                        visited: 0,//是否来访
                        cognitivePathway: "",//认知途径
                        leaseMethod:"",//租购
                        firstCommunicationRecord: '',//首次沟通记录
                        firstCommunicationTime: '',//首次沟通时间
                        company: "",
                        industry: "",
                        address: "",
                        contactsName: "",
                        contactsPhone: "",
                        jobTitle: "",
                        decisionMaker: "", //是否决策人，必填
                        intention: "",
                        source: "",
                        comment: "",
                    },
                },
                // 跟进客户
                followDialog: {
                    visible: false,
                    onClose: () => (this.followDialog.visible = false),
                    data: {intention: "", content: "", contactsId: ""},
                    onConfirm() {
                    },
                },
                // 意向房间
                addIntentionDialog: {
                    visible: false,
                    roomIds: [],
                    treePanelVisible: false,
                    rooms: [],
                    roomNames: [],
                    onClose: () => {
                        this.addIntentionDialog.visible = false;
                        this.addIntentionDialog.treePanelVisible = false;
                    },
                    onChangeRooms: (checkBoxInfo) => {
                        const {key, source} = checkBoxInfo,
                            checkbox = source[key],
                            {roomIds, roomNames} = this.addIntentionDialog;
                        if (!checkbox?.children) {
                            const index = roomIds.indexOf(checkbox.id);
                            if (index > -1) {
                                checkbox.checked = false;
                                roomNames.splice(index, 1);
                                roomIds.splice(index, 1);
                            } else {
                                checkbox.checked = true;
                                roomIds.push(checkbox.id);
                                roomNames.push(checkbox.fullName);
                            }
                        } else {
                            this.changeChildrenCheckState(
                                checkbox,
                                {checkedIds: roomIds, roomNames},
                                !checkbox.checked
                            );
                        }
                    },
                    onConfirm() {
                    },
                },
                // 认租房间
                confessToRentDialog: {
                    visible: false,
                    roomIds: [],
                    treePanelVisible: false,
                    rooms: [],
                    roomNames: [],
                    onClose: () => {
                        this.confessToRentDialog.visible = false;
                        this.confessToRentDialog.treePanelVisible = false;
                    },
                    onChangeRooms: (checkBoxInfo) => {
                        const {key, source} = checkBoxInfo,
                            checkbox = source[key],
                            {roomIds, roomNames} = this.confessToRentDialog;
                        if (!checkbox?.children) {
                            const index = roomIds.indexOf(checkbox.id);
                            if (index > -1) {
                                checkbox.checked = false;
                                roomNames.splice(index, 1);
                                roomIds.splice(index, 1);
                            } else {
                                checkbox.checked = true;
                                roomIds.push(checkbox.id);
                                roomNames.push(checkbox.fullName);
                            }
                        } else {
                            this.changeChildrenCheckState(
                                checkbox,
                                {checkedIds: roomIds, roomNames},
                                !checkbox.checked
                            );
                        }
                    },
                    onConfirm() {
                    },
                },
                // 是否决策人
                decisionMaking: {
                    true: "是",
                    false: "否",
                },
                leadsLevel: {
                    1: "A",
                    2: "B",
                    3: "C",
                    4: "D",
                    5: "E",
                },
                cognitivePathwayList: {
                    0: '否',
                    1: '是'
                },
                queryParams: {
                    regionCode: this.$vc.getCurrentRegion().code,
                    keyword: "",
                    source: "",
                    industry: "",
                    intention: "",
                    orderBy: 1,
                    target: "",
                    ownerId: "",
                },
                subscribeAuditState: {
                    0: "未认租",
                    1: "待上级审核",
                    2: "上级审核驳回",
                    3: "待财务审核",
                    4: "财务审核驳回",
                    5: "审核通过",
                },
                publicCustomerList: [],
                privateCustomerList: [],
                subscriptionCustomerList: [],
                staffInfo: this.$vc.getCurrentStaffInfo(),
                staffList: [],
                //楼书
                buildingBookInfo: {
                    id: "",
                    regionCode: "",
                    portrait: "",
                    phone: "",
                    slogan: "",
                    userId: "",
                    shareImageUrl: "",
                },
                queryCountUvPvByUserParams: {
                    startDate: dayjs().subtract(0, "day").format("YYYY-MM-DD 00:00"),
                    endDate: dayjs().format("YYYY-MM-DD 23:59"),
                    userId: "",
                },
                UvPv: {
                    uv: "",
                    pv: "",
                },
                //楼书二维码
                buildBookDialog: {
                    visible: false,
                    qrCode: "",
                    onClose: () => (this.buildBookDialog.visible = false),
                },
                pageParams: {
                    pageNo: 1,
                    pageSize: 10
                }
            };
        },
        async mounted() {

            const staffInfo = this.$vc.getCurrentStaffInfo();
            if (
                ![JOB_LEVEL.SUPERVISOR, JOB_LEVEL.TEAM_SUPERVISOR].includes(
                    staffInfo.jobLevel
                )
            ) {
                this.$set(this.tabbar[TAB_BAR_TYPE.PRIVATE], "children", []);
                this.$set(this.tabbar[TAB_BAR_TYPE.SUBSCRIPTION], "children", []);
            }
            let departmentCode = staffInfo.departmentCode,
                staffList = [];
            if (
                [DEPARTMENT_TYPES.DIRECTOR, DEPARTMENT_TYPES.MANAGER].includes(
                    staffInfo.dutyType
                ) &&
                staffInfo.jobLevel === JOB_LEVEL.SUPERVISOR
            ) {
                const departmentList = await this.queryDepartmentList(
                    ORG_LEVEL.DEPARTMENT,
                    1,
                    100,
                    staffInfo.companyCode
                );
                for (let val of departmentList) {
                    if (val.dutyType == staffInfo.dutyType) {
                        departmentCode = val.code;
                        const groupList = await this.queryDepartmentList(
                            ORG_LEVEL.GROUP,
                            1,
                            100,
                            val.code
                        );
                        for (let group of groupList) {
                            staffList.push(...(await this.getStaffList(group.code)));
                        }
                        return;
                    }
                }
            } else {
                const groupList = await this.queryDepartmentList(
                    ORG_LEVEL.GROUP,
                    1,
                    100,
                    departmentCode
                );
                for (let group of groupList) {
                    staffList.push(...(await this.getStaffList(group.code)));
                }
            }
            this.staffList = await this.getStaffList(departmentCode);
            this.changeTabBar(this.checkedTab, "checkedTab");
            this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
                this.pageParams.pageNo = pageNo;
                this.searchData(pageNo);
            });
            this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            window.addEventListener("resize", () => {
                this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            });
        },
        methods: {
            chooseExcel(event) {
                return this.$vc.chooseFile(event, (file) => {
                    const excelTypes = ["xlsx", "xls", "xlsm", "xlsb", "xltx", "xlt"],
                        fileSplitArr = file.name.split(".");
                    if (!excelTypes.includes(fileSplitArr[fileSplitArr.length - 1])) {
                        this.$vc.message(`仅能上传${excelTypes.join("、")}格式的文件`);
                        return;
                    }
                    this.uploadExcelDialog.file = file;
                });
            },
            triggerChooseExcel() {
                document.querySelector("#uploadExcel").click();
            },
            uploadExcel() {
                const {file, source, regionCode} = this.uploadExcelDialog;
                let formData = new FormData();
                if (file === null || file.name === "") {
                    this.$vc.toast("请选择Excel");
                    return;
                }
                // if (source === null || source === "") {
                //   this.$vc.toast("请选择客户来源");
                //   return;
                // }
                formData.append("file", file);
                //formData.append("source", source);
                formData.append("regionCode", regionCode);
                this.$fly
                    .post(leaseCrmUploadExcelUrl, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        if (res.data && res.data.length > 0) {
                            this.$vc.message(res.data[0]);
                            return;
                        }
                        this.$vc.toast("上传成功");
                        this.closeUploadExcel();
                        this.searchData();
                    });
            },
            closeUploadExcel() {
                this.uploadExcelDialog.visible = false;
            },
            //导入客户
            importCustomersBtn() {
                this.uploadExcelDialog.file = {
                    name: ""
                };
                this.uploadExcelDialog.visible = true;
            },
            async openBuildBookDialog() {
                await this.$fly
                    .get(generateQrCodeUrl)
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.buildBookDialog.qrCode = res.data;
                        this.buildBookDialog.visible = true;
                    });
            },
            // 返回意向房间名称
            getIntentionRoomNames(rooms, showLabel = false) {
                let subscription = false;
                let roomHtml = `<div class="allow-click" style="max-width: 200px;">${rooms
                    .map((room) => {
                        if (room.subscription) {
                            subscription = true;
                        }
                        return `${room.buildingName}-${room.roomName}`;
                    })
                    .toString()}</div>`;
                if (subscription && showLabel) {
                    roomHtml +=
                        '<span class="cs-tag error" style="margin-left: 10px;">房间已被认租</span>';
                }
                return roomHtml;
            },
            /**
             * 查看审核评论
             * @param {String} comment 评论内容
             * @param {Number} status 审核状态
             * */
            lookSubscribeAuditComment(comment, status) {
                this.$CSDialog.alert({
                    title: [2, 4].includes(status) ? "驳回原因" : "通过",
                    message: comment,
                });
            },
            /**
             * 查询公司/部门接口
             * @param {Number} level 0:代表公司
             * @param {NUmber} pageNo 分页页数
             * @param {Number} pageSize 每次查看的数量
             * @param {Number | String} parentCode 公司code
             *  */
            queryDepartmentList(
                level = ORG_LEVEL.COMPANY,
                pageNo = 1,
                pageSize = 100,
                parentCode
            ) {
                return this.$fly
                    .post(queryDepartmentUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        code: "",
                        name: "",
                        dutyType: "",
                        parentCode,
                        level,
                        pageNo,
                        pageSize: level == ORG_LEVEL.COMPANY ? 100 : pageSize,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return [];
                        }

                        return res.data;
                    });
            },
            // 获取员工列表
            getStaffList(departmentCode) {
                return this.$fly
                    .post(queryDepartmentStaffUrl, {
                        regionCode: this.$vc.getCurrentRegion().code || "",
                        departmentCode,
                        jobLevel: "",
                        isOnTheJob: true,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        return res.data;
                    });
            },
            /**
             * 获取选中的意向房间
             * @param {Number} leadId 客户池线索id
             * */
            queryIntentionRooms(leadId) {
                return this.$fly
                    .get(queryIntentionRoomsUrl, {
                        leadId,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        return res.data;
                    });
            },
            /**
             * 查看房间
             * @param {Number} type 1:意向房间   2:认租房间
             * @param {Array} rooms 选中的房间
             * @param {Boolean} isDelete 是否允许删除
             * @param {Number} leadId 线索id
             * */
            async lookIntentionRooms(type, rooms, isDelete = false, leadId) {
                if (isDelete) {
                    this.lookIntentionDialog.visible = true;
                    this.lookIntentionDialog.rooms = rooms.map((room) => {
                        room.fullName = room.buildingName + "-" + room.roomName;
                        room.hasTenant = room.subscription;
                        return room;
                    });
                    this.lookIntentionDialog.leadId = leadId;
                    return;
                }
                let roomHtml = "";
                rooms.forEach((room) => {
                    roomHtml += `<p>${room.buildingName}-${room.roomName}</p>`;
                });
                this.$CSDialog.alert({
                    title: `${type === 1 ? "意向" : "认租"}房间`,
                    width: "400px",
                    messageHtml: `
                <div class="comment-panel text-center">
                    <div class="item text-center">
                        <div class="text-center" style="max-width: 100%;">
                            ${roomHtml}
                        </div>
                    </div>
                </div>
                `,
                });
            },
            /**
             * 查看备注
             * @param {String} comment 备注信息
             *  */
            lookCustomerComment(comment) {
                this.$CSDialog.alert({
                    title: "备注",
                    message: comment,
                });
            },
            /**
             * 更改下级的选中状态
             *  */
            changeChildrenCheckState(checkbox, {checkedIds, roomNames}, isChecked) {
                checkbox.checked = isChecked;
                if (checkbox.children && checkbox.children.length > 0) {
                    checkbox.children.forEach((child) => {
                        this.changeChildrenCheckState(
                            child,
                            {checkedIds, roomNames},
                            isChecked
                        );
                    });
                } else if (!checkbox.floors && !checkbox.rooms && checkbox.allowCheck) {
                    if (isChecked) {
                        checkedIds.push(checkbox.id);
                        roomNames.push(checkbox.fullName);
                    } else {
                        const index = checkedIds.indexOf(checkbox.id);
                        if (index > -1) {
                            roomNames.splice(index, 1);
                            checkedIds.splice(index, 1);
                        }
                    }
                }
            },
            /**
             * 打开意向房间弹窗
             * @param {Number} leadId 线索id
             * @param {Array} intentionRooms 选中的意向房间
             *  */
            async openAddIntentionDialog(leadId, intentionRooms = []) {
                this.addIntentionDialog.roomIds = [];
                this.addIntentionDialog.roomNames = [];
                const buildingResult = await this.queryBuildingRooms(),
                    checkedIntentionRoomIds = intentionRooms.map((room) => room.roomId),
                    {roomIds, roomNames} = this.addIntentionDialog;
                const rooms = buildingResult.map((building) => {
                    building.id = building.code;
                    building.allowCheck = false;
                    building.checked = false;
                    building.hasChildChecked = false;
                    building.floors.sort((preFloor, floor) => {
                        const preFloorNum = Number(preFloor.name),
                            floorNum = Number(floor.name);
                        if (preFloorNum > floorNum) {
                            return 1;
                        }

                        if (preFloorNum < floorNum) {
                            return -1;
                        }
                        return 0;
                    });

                    building.children = building.floors.map((floor, floorIndex) => {
                        floor.id = floorIndex;
                        floor.allowCheck = false;
                        floor.checked = false;
                        floor.hasChildChecked = false;
                        floor.name = floor.name + "层";
                        floor.children = floor.rooms.map((room) => {
                            room.id = room.roomId;
                            room.checked =
                                checkedIntentionRoomIds.includes(room.roomId) || false;
                            room.fullName = building.name + "-" + room.name;
                            room.allowCheck = room.preContracts <= 0 && room.contracts <= 0;
                            if (room.allowCheck) {
                                floor.allowCheck = true;
                            }
                            room.name = `${room.name}${
                                room.contracts > 0
                                    ? "(在租中)"
                                    : room.preContracts > 0
                                    ? "(认租中)"
                                    : ""
                            }`;
                            // 回选中意向房间
                            if (checkedIntentionRoomIds.includes(room.roomId)) {
                                roomIds.push(room.roomId);
                                roomNames.push(room.fullName);
                            }
                            return room;
                        });
                        if (floor.allowCheck) {
                            building.allowCheck = true;
                        }
                        return floor;
                    });
                    return building;
                });
                console.log(rooms);
                this.addIntentionDialog.rooms = rooms;
                this.addIntentionDialog.onConfirm = () => {
                    this.confirmIntentionRoom({
                        leadId,
                        roomIds: this.addIntentionDialog.roomIds,
                        type: 1,
                        regionCode: this.$vc.getCurrentRegion().code,
                    }).then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.addIntentionDialog.onClose();
                        this.$vc.toast("提交成功");
                        this.searchData();
                    });
                };
                this.addIntentionDialog.visible = true;
            },
            /**
             * 确认意向房间或者认租房间
             * @param {Object} params
             *  */
            confirmIntentionRoom(params) {
                return this.$fly.post(addIntentionRoomsUrl, params).then((res) => res);
            },
            /**
             * 打开认租弹窗
             * @param {Number} leadId 线索id
             * @param {Array} intentionRooms 意向房间
             *  */
            async openConfessToRentDialog(leadId, intentionRooms = []) {
                this.confessToRentDialog.expiredDays = "";
                this.confessToRentDialog.moneyAmount = "";
                this.confessToRentDialog.roomIds = [];
                this.confessToRentDialog.roomNames = [];
                const buildingResult = await this.queryBuildingRooms(),
                    checkedIntentionRoomIds = intentionRooms.map((room) => room.roomId),
                    {roomIds, roomNames} = this.confessToRentDialog;
                const rooms = buildingResult.map((building) => {
                    building.id = building.code;
                    building.allowCheck = false;
                    building.checked = false;
                    building.hasChildChecked = false;
                    building.floors.sort((preFloor, floor) => {
                        const preFloorNum = Number(preFloor.name),
                            floorNum = Number(floor.name);
                        if (preFloorNum > floorNum) {
                            return 1;
                        }

                        if (preFloorNum < floorNum) {
                            return -1;
                        }
                        return 0;
                    });
                    building.children = building.floors.map((floor, floorIndex) => {
                        floor.id = floorIndex;
                        floor.allowCheck = false;
                        floor.checked = false;
                        floor.hasChildChecked = false;
                        floor.children = floor.rooms.map((room) => {
                            room.id = room.roomId;
                            room.checked =
                                checkedIntentionRoomIds.includes(room.roomId) || false;
                            room.fullName = building.name + "-" + room.name;
                            room.allowCheck = room.preContracts <= 0 && room.contracts <= 0;
                            if (room.allowCheck) {
                                floor.allowCheck = true;
                            }
                            room.name = `${room.name}${
                                room.contracts > 0
                                    ? "(在租中)"
                                    : room.preContracts > 0
                                    ? "(认租中)"
                                    : ""
                            }`;
                            // 回选中意向房间
                            if (checkedIntentionRoomIds.includes(room.roomId)) {
                                roomIds.push(room.roomId);
                                roomNames.push(room.fullName);
                            }
                            return room;
                        });
                        if (floor.allowCheck) {
                            building.allowCheck = true;
                        }
                        floor.name = floor.name + "层";
                        return floor;
                    });
                    return building;
                });
                this.confessToRentDialog.rooms = rooms;

                this.confessToRentDialog.onConfirm = () => {
                    this.confirmIntentionRoom({
                        leadId,
                        roomIds: this.confessToRentDialog.roomIds,
                        type: 2,
                        regionCode: this.$vc.getCurrentRegion().code,
                        expiredDays: this.confessToRentDialog.expiredDays,
                        moneyAmount: this.confessToRentDialog.moneyAmount,
                    }).then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.confessToRentDialog.onClose();
                        this.$vc.toast("提交成功");
                        this.searchData();
                    });
                };
                this.confessToRentDialog.visible = true;
            },
            // 获取房间
            queryBuildingRooms() {
                return this.$fly
                    .get(queryBuildingRoomsUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        search: "",
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        return res.data;
                    });
            },
            /**
             * 前往客户详情页面
             * @param {Number} leadId 销售线索id
             * @param {Number} tabId 目标页面中的选中的tab
             * @param {Number} sourceType 客户池标识   1：公有客户池  2：私有客户池   3：认租客户池
             * @param {Number} staffId 员工id
             *  */
            toCustomerInfoPage(leadId, tabId, sourceType, staffId = 0) {
                this.$router.push({
                    name: "customerInfo",
                    params: {
                        leadId,
                        tabId,
                        sourceType,
                        staffId,
                    },
                });
            },
            // 查询认租客户
            querySubscriptionCustomer(pageNo = 1, pageSize = 10) {
                console.log("querySubscriptionCustomer=", this.checkedTab, this.checkedChildTab)
                if (this.checkedTab === 3 && this.checkedChildTab === 1) {
                    this.queryParams.target = 1;
                }
                if (this.checkedTab === 3 && this.checkedChildTab === 2) {
                    this.queryParams.target = 2;
                }
                const {regionCode, keyword, source, industry, target, ownerId} =
                    this.queryParams;
                this.$fly
                    .post(querySubscriptionsUrl, {
                        pageNo,
                        pageSize,
                        regionCode,
                        keyword,
                        source,
                        industry,
                        state: 3,
                        target,
                        ownerId,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        if (pageNo === 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total || res.data.datas.length,
                                currentPage: pageNo,
                                pageSize: res.data.total ? pageSize : res.data.datas.length,
                            });
                        }
                        this.subscriptionCustomerList = res.data.datas;
                    });
            },
            /**
             * 释放私有客户
             * @param {Number} leadId 销售线索id
             *  */
            releaseOpportunity(leadId) {
                this.$CSDialog.confirm({
                    title: "提示",
                    message: "确定释放吗？",
                    onConfirm: () => {
                        this.$fly
                            .post(releaseOpportunityUrl, {
                                regionCode: this.$vc.getCurrentRegion().code,
                                leadId,
                            })
                            .then((res) => {
                                if (res.code !== 0) {
                                    return;
                                }
                                this.$vc.toast("释放成功");
                                this.$CSDialog.instance.closeDialog();
                                this.queryOpportunity();
                            });
                    },
                });
            },
            // 打开添加私有客户
            openAddPrivateCustomer() {
                const staffInfo = this.$vc.getCurrentStaffInfo();
                this.addCustomerDialog.visible = true;
                this.addCustomerDialog.showTip = false;
                this.addCustomerDialog.data = {
                    company: "",
                    industry: "",
                    address: "",
                    contactsName: "",
                    contactsPhone: "",
                    jobTitle: "",
                    decisionMaker: "", //是否决策人，必填
                    intention: "",
                    source: "",
                    comment: "",
                    receptionist: '',//接待人
                    returnVisitRecord: '',//回访记录
                    focus: '',//关注点
                    estimatedOccupancyTime: '',// 预计用房时间
                    requiredArea: '',// 需求面积
                    purposeOfSiteSelection: '',//新加 选址目的 1:办公需求;2:生产需求;3仓库需求;4:商业配套需求
                    companyNumber: '',//企业人数
                    officeArea: '',//现有办公面积
                    affiliationLocation: '',//办公地址所属区位
                    mainBusiness: '',//主营业务
                    visited: 0,//是否来访
                    cognitivePathway: "",//认知途径
                    firstCommunicationRecord: '',//首次沟通记录
                    firstCommunicationTime: '',//首次沟通时间
                    leaseMethod:""//租购

                };
                this.addCustomerDialog.onConfirm = async () => {
                    const addRes = await this.addOpportunity();
                    // this.addCustomerDialog
                    if (addRes === null) {
                        return;
                    }
                    if (!addRes.success) {
                        this.addCustomerDialog.showTip = true;
                        const errMsg = {
                            1: "无法添加，已在公海客户池",
                            2: `无法添加，已在${
                                staffInfo.id === addRes?.belongingTo ? "自己" : "其他顾问"
                            }的私有客户池`,
                            3: `无法添加，已在${
                                staffInfo.id === addRes?.belongingTo ? "自己" : "其他顾问"
                            }的认租客户池`,
                            4: `无法添加，已在${
                                staffInfo.id === addRes?.belongingTo ? "自己" : "其他顾问"
                            }的临时获取客户池`,
                        };
                        this.addCustomerDialog.showTipText =
                            errMsg[addRes?.leadState] || addRes?.errMsg;
                        if (addRes?.canTransfer) {
                            this.addCustomerDialog.showTransferBtn = true;
                            this.addCustomerDialog.addPrivatePool = () => {
                                this.addOpportunityFailToPrivatePool(
                                    addRes?.existsId,
                                    addRes.token
                                );
                            };
                        }
                        return;
                    }
                    this.addCustomerDialog.onClose();
                    this.queryOpportunity();
                };
            },
            /**
             * 添加客户失败后可转到私有客户池
             * @param {Number} leadId 线索id
             * @param {String} token 转移时需要的token
             * */
            addOpportunityFailToPrivatePool(leadId, token) {
                this.$fly
                    .post(publicToOpportunityUrl, {
                        leadId,
                        token,
                        regionCode: this.$vc.getCurrentRegion().code,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.$vc.toast("转移成功");
                        this.addCustomerDialog.onClose();
                        this.queryOpportunity();
                    });
            },
            // 添加客户
            addOpportunity() {
                let resParams = this.addCustomerDialog.data;
                if ((resParams.officeArea.includes(".") && resParams.officeArea.split(".")[1].length > 2) || (resParams.requiredArea.includes(".") && resParams.requiredArea.split(".")[1].length > 2)) {
                    this.$vc.message("可保留两位小数");
                    return;
                }
                if (resParams.companyNumber.includes(".")) {
                    this.$vc.message("企业人数限整数");
                    return;
                }
                return this.$fly
                    .post(addOpportunityUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        ...this.addCustomerDialog.data,
                        decisionMaker: Boolean(this.addCustomerDialog.data.decisionMaker),
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return null;
                        }
                        return {
                            ...res.data,
                            errMsg: res.msg,
                        };
                    });
            },
            // 查询私有客户
            queryOpportunity(pageNo = 1, pageSize = 10) {
                if (this.checkedTab === 2 && this.checkedChildTab === 2) {
                    this.queryParams.target = 2;
                }
                if (this.checkedTab === 2 && this.checkedChildTab === 1) {
                    this.queryParams.target = 1
                }
                const {
                    regionCode,
                    keyword,
                    source,
                    industry,
                    intention,
                    orderBy,
                    target,
                    ownerId,
                } = this.queryParams;
                this.$fly
                    .post(queryOpportunityUrl, {
                        pageNo,
                        pageSize,
                        regionCode,
                        keyword,
                        source,
                        industry,
                        intention,
                        orderBy,
                        target,
                        ownerId,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        if (pageNo === 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total || res.data.datas.length,
                                currentPage: pageNo,
                                pageSize: res.total ? pageSize : res.data.datas.length,
                            });
                        }
                        this.privateCustomerList = res.data.datas;
                    });
            },
            /**
             * 获取销售资源对应联系人
             * @param {Number} leadId 销售线索id
             *  */
            getLeadContacts(leadId) {
                return this.$fly.get(queryLeadContactsUrl, {leadId}).then((res) => {
                    if (res.code !== 0) {
                        return [];
                    }
                    return res.data;
                });
            },
            // 打开跟进弹窗
            async openFollowDialog(leadId) {
                this.followDialog.contacts = await this.getLeadContacts(leadId);
                this.followDialog.visible = true;
                this.followDialog.data = {intention: "", content: "", contactsId: ""};
                this.followDialog.onConfirm = () => {
                    this.$fly
                        .post(addFollowUpLogUrl, {
                            ...this.followDialog.data,
                            leadId,
                            regionCode: this.$vc.getCurrentRegion().code,
                        })
                        .then((res) => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$vc.toast("跟进完成");
                            this.followDialog.onClose();
                            this.searchData();
                        });
                };
            },
            // 点击查询按钮
            searchData(pageNo = this.pageParams.pageNo) {
                if (this.checkedTab === 4 && this.checkedChildTab === 1) {
                    this.queryCountUvPvByUser();
                    return;
                }
                if (this.checkedTab === 4 && this.checkedChildTab === 2) {
                    console.log('查询');
                    this.getBuildingBook();
                    return;
                }
                const tabActivity = {
                    [TAB_BAR_TYPE.PUBLIC]: this.getPublicCustomer,
                    [TAB_BAR_TYPE.PRIVATE]: this.queryOpportunity,
                    [TAB_BAR_TYPE.SUBSCRIPTION]: this.querySubscriptionCustomer,
                    [TAB_BAR_TYPE.BUILDINGBOOK]: this.querySubscriptionCustomer,
                };
                tabActivity[this.checkedTab](pageNo);
            },
            /**
             * 更改选中的tab
             * @param {Number} id 选中的tab
             * @param {Object} checkedName 更改的属性名
             *  */
            changeTabBar(id, checkedName) {
                this[checkedName] = id;
                //
                if (id != TAB_BAR_TYPE.PUBLIC && checkedName === "checkedTab") {
                    this.queryParams.target = 1;
                    this.checkedChildTab = 1;
                }
                if (checkedName === "checkedChildTab") {
                    this.queryParams.target = id;
                }
                this.searchData();
            },
            // 查看销售资源等级
            lookLeadsLevels() {
                this.$CSDialog.alert({
                    width: 760,
                    title: "意向程度说明",
                    messageHtml: `
                <ul class="customer-levels-tips">
                    <li>A：准客户，7天内认租。</li>
                    <li>
                        B：谈判客户，已来项目查看，处于谈判中，30天内认租。
                    </li>
                    <li>C：意向客户，对项目认可，需持续跟进。</li>
                    <li>D：潜在客户，有租赁需求，需持续跟进。</li>
                    <li>E：暂无意向。</li>
                </ul>
            `,
                });
            },
            // 获取公共客户池
            getPublicCustomer(pageNo = this.pageParams.pageNo, pageSize = 10) {
                const {keyword, source, industry, regionCode} = this.queryParams;
                this.$fly
                    .post(queryPublicCustomerUrl, {
                        keyword,
                        source,
                        industry,
                        regionCode,
                        pageNo,
                        pageSize,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        if (pageNo === 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total || res.data.datas.length,
                                currentPage: pageNo,
                                pageSize: res.data.total ? pageSize : res.data.datas.length,
                            });
                        }
                        this.publicCustomerList = res.data.datas;
                    });
            },
            // 获取客户
            getCustomerLeads() {
                this.$CSDialog.confirm({
                    title: "提示",
                    message: "确定获取客户吗？",
                    onConfirm: () => {
                        this.$fly
                            .post(getCustomerLeadsUrl, {
                                regionCode: this.$vc.getCurrentRegion().code,
                            })
                            .then((res) => {
                                if (res.code !== 0) {
                                    return;
                                }
                                this.$vc.toast("获取客户成功");
                                this.$CSDialog.instance.visible = false;
                                this.getPublicCustomer();
                            });
                    },
                });
            },
            /**
             * 添加到私有客户池
             * @param {Number} lockedLeadId 销售资源线索的锁定id
             *  */
            addPrivateCustomerLeads(lockedLeadId) {
                this.$CSDialog.confirm({
                    title: "提示",
                    message: "确认添加吗",
                    onConfirm: () => {
                        this.$fly
                            .post(lockedToOpportunityUrl, {
                                regionCode: this.$vc.getCurrentRegion().code,
                                lockedLeadId,
                            })
                            .then((res) => {
                                if (res.code !== 0) {
                                    return;
                                }
                                this.$vc.toast("添加至私有客户成功");
                                this.$CSDialog.instance.closeDialog();
                                this.getPublicCustomer();
                            });
                    },
                });
            },

            searchDataDown(e) {
                if (e.keyCode == 13) {
                    this.searchData();
                }
            },

            //楼书 ----- 开始
            /**
             * 查询个人楼书的UvPv
             */
            getBuildingBook() {
                this.$fly.get(getBuildingBookUrl, null).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.buildingBookInfo = res.data;
                    if (!this.buildingBookInfo.slogan) {
                        this.buildingBookInfo.slogan = '为您提供全方位入驻服务。'
                    }
                });
            },
            settingBuildingBook() {
                const params = {
                    portrait: this.buildingBookInfo.portrait,
                    phone: this.buildingBookInfo.phone,
                    slogan: this.buildingBookInfo.slogan,
                };
                this.$fly.post(settingBuildingBookUrl, params).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast("保存成功");
                });
            },
            //查询PvUv访问量
            queryCountUvPvByUser() {

                this.queryCountUvPvByUserParams.userId =
                    this.$vc.getCurrentStaffInfo().id;
                this.$fly
                    .post(queryCountUvPvByUserUrl, this.queryCountUvPvByUserParams)
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.UvPv = res.data;
                    });
            },
            queryUV() {
                //带过去的参数
                const params = {
                    startDate: this.queryCountUvPvByUserParams.startDate,
                    endDate: this.queryCountUvPvByUserParams.endDate,
                    uvCount: this.UvPv.uv,
                    userId: this.$vc.getCurrentStaffInfo().id,
                };
                this.$router.push({
                    name: "uv",
                    params: params,
                });
            },
            queryPV() {
                //带过去的参数
                const params = {
                    startDate: this.queryCountUvPvByUserParams.startDate,
                    endDate: this.queryCountUvPvByUserParams.endDate,
                    pvCount: this.UvPv.pv,
                    userId: this.$vc.getCurrentStaffInfo().id,
                };
                console.log(params)
                this.$router.push({
                    name: "pv",
                    params: params,
                });
            },

            //-=================
            //添加上传照片
            uploadAddEnterprisePhoto() {
                $("#uploadAddEnterprisePhotoId").trigger("click");
            },
            deleteFacePhoto() {
                this.buildingBookInfo.portrait = "";
            },
            showImage(event) {
                var photoFiles = event.target.files;
                if (photoFiles && photoFiles.length > 0) {
                    var file = photoFiles[0];
                    if (file.size > 1024 * 1024 * 2) {
                        this.$vc.toast("图片大小不能超过 2M!");
                        return false;
                    }
                }
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = async () => {
                    let imgSrc = await this.uploadPhoto(reader.result).catch(() => {
                        this.$vc.toast("上传图片错误,请重新上传");
                    });
                    if (imgSrc && imgSrc != "") {
                        this.$set(this.buildingBookInfo, "portrait", imgSrc)
                    }
                    event.target.value = "";
                };
            },
            //异步上传照片
            async uploadPhoto(base64) {
                return new Promise((reslove, reject) => {
                    this.$vc.http.post(
                        "uploadImg",
                        "upload",
                        {
                            img: base64,
                        },
                        {
                            headres: {
                                "Content-Type": "application/json",
                            },
                        },
                        (resStr, res) => {
                            reslove(res.body.fileSaveName);
                        },
                        (errText, err) => {
                            reject(errText);
                        }
                    );
                });
            },
        },
        created() {
            window.addEventListener("keydown", this.searchDataDown);
        },
        beforeDestroy() {
            window.addEventListener("keydown", this.searchDataDown);
        },
    };
</script>


<style lang="stylus" scoped>
    @import './filter-panel.styl';

    .filter-panel {
        .icon-menubianzu1-copy {
            // background #1ab394
            color: #fff;
            font-size: 20px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            line-height: 36px;
            margin-right: 20px;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            margin-bottom: 15px;
            margin-top: -3px;
        }
    }

    table {
        th {
            img {
                width: 14px;
                height: 14px;
            }
        }

        /* th, td
        white-space nowrap
        text-overflow ellipsis */
    }

    .add-customer-content, .follow-dialog-content, .confess-rent-dialog-content {
        padding: 27px 30px 30px 30px;
    }

    .follow-dialog-content {
        .form-item-label {
            width: 96px;
        }
    }

    .confess-rent-dialog-content {
        .form-item-label {
            width: 120px;

            & + div {
                max-width: calc(100% - 120px - 20px);
            }
        }
    }

    .form-item {
        font-size: 24px;

        &-label {
            margin-right: 20px;
            width: 240px;
            color: #444;
            text-align right
        }

        &:not(:last-of-type) {
            margin-bottom: 23px;
        }

        &.error {
            margin-bottom: 5px;

            .form-item-label {
                margin-top: -30px;
            }
        }

        div {
            display: inline-block;
            vertical-align: middle;
            max-width: 720px;

            // position relative

            .tips {
                /* position absolute
                bottom -30px
                left 0 */
                font-size: 20px;
                line-height: 20px;
                color: #f00;
            }
        }

        .cs-input {
            border-radius: 4px;
            height: 40px;
            border: 1px solid #979797;
            width: 400px;
            padding: 0 8px;
            box-sizing: border-box;

            &::placeholder {
                color: #999;
            }
        }

        .cs-select {
            display: flex;
            border: 1px solid #979797;

            .cs-input {
                border: none !important;
                outline: none;
            }

            select {
                width: 220px;
                color: #999;
            }
        }

        textarea.cs-input {
            width: 624px;
            height: 200px;
            border: none;
            outline: none;
            resize: none;
            background: #f0f0f0;
            padding: 10px;
            border-radius: 4px;
        }
    }

    .tree-wrap {
        max-width: 100%;
        position: relative;

        .cs-input {
            width: 215px;
        }
    }

    .room-select {
        width: 215px;
        overflow: hidden;
        height: 40px;
        display: inline-flex !important;
        flex-flow: row nowrap;
        align-items: center;
        line-height: 40px;
        padding: 0 6px;

        &.unselect {
            color: #999;
        }

        span {
            height: 30px;
            line-height: 30px;
            background: #1ab394;
            padding: 0 10px;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            color: #fff;
            border-radius: 15px;

            &:not(:last-of-type) {
                margin-right: 10px;
            }
        }
    }

    .dropdown-menu {
        li {
            a {
                padding: 3px 6px;
            }
        }
    }

    .building-book-setting {
        height: 684px;
        background: #ffff;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        overflow: hidden;
        padding-left 20px;
    }

    .building-book-setting > span {
        display: inline-block;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
        opacity: 1;
    }

    .building-book-setting > input {
        width: 192px;
        height: 30px;
        border: 1px solid #999999;
        opacity: 1;
        border-radius: 3px;
        margin-left: 20px;
    }

    .building-book-setting .setting-hint {
        margin-top: 15px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #999999;
        opacity: 1;
    }

    .upload-excel-dialog {
        padding: 27px 30px 30px 30px;

        .form-item {
            font-size: 24px;

            &-label {
                width: 108px;
                margin-right: 40px;
                text-align: right;
                color: #000;
            }

            &:not(:last-of-type) {
                margin-bottom: 23px;
            }

            div {
                display: inline-block;
                vertical-align: middle;

                .btn-primary {
                    width: 80px;
                    height: 40px;
                    padding: 0;
                    line-height: 40px;
                    font-size: 24px;
                    border-radius: 6px;
                    vertical-align: middle;
                }
            }

            .cs-input {
                width: 250px;
                border: 1px solid #979797;
                border-radius: 4px;
                vertical-align: middle;

                &::placeholder {
                    color: #999;
                }
            }

            /* rgb(170, 170, 170) */

            .cs-select {
                display: flex;
                --icon-line-height: 40px;

                select {
                    width: 238px;
                    height: 100%;
                }
            }
        }
    }
</style>
